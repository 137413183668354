"use strict";

class CustomerLoginController {
    constructor($scope, $window, $localStorage, $parse, $timeout, SSUserService, SSAppService, SSHttpService, SSUtilService, SSConfirmationService, SSAlertService, $rootScope) {

        const self = this;

        self.$scope = $scope;
        self.$window = $window;
        self.$storage = $localStorage;
        self.$parse = $parse;
        self.$timeout = $timeout;
        self.$rootScope = $rootScope;

        self.SSUserService = SSUserService;
        self.SSAppService = SSAppService;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSAlertService = SSAlertService;

        self.FIREBASE_VAPID_KEY = SHOWSUITE.FIREBASE_VAPID_KEY;

        self.TYPE_2FA_LOGIN = 2;

        self.TOKEN_SENT_SUCCESSFULLY = 1;
        self.TOKEN_WAS_ALREADY_SENT = 2;
        self.TOKEN_SENT_ERROR = 3;
        self.TOKEN_MISSING_REQUIRED_PARAMS = 4;

        self.USER_ROLE_BUYER = 1;
        self.USER_ROLE_BROKER = 2;

        self.agencies = [];
        self.init();

        self.enquireSuccess = false;
        self.equiryStatus = false;
        self.confirmEnquiryInProcess = false;
        self.registerError = null;
        self.isDeveloper = false;
        self.buyer_id = null;

        self.allow_notifications = true;
    }

    init() {

        const self = this;

        self.SSAppService.needServerConfig();
        self.appServiceObject = self.SSUtilService.defineStorageDefault('def_app_service');
        self.DMS_AGR =  (self.appServiceObject.serverConfig && self.appServiceObject.serverConfig.DMS_AGREEMENT)?self.appServiceObject.serverConfig.DMS_AGREEMENT:false;
        //console.log('Agreement');
        //console.log(self.DMS_AGR);
        //if(self.SSUserService.getUser()){
        //    console.log(self.SSUserService.getUser().agreement);
        //    console.log(self.SSUserService.getUser().role);
        //}

        self.show = self.SSUtilService.getURLParameter('show');
        self.redirectTo = self.SSUtilService.getURLParameter('redirect');
        self.activationToken = self.SSUtilService.getURLParameter('activation_token');
        self.forgetPasswordToken = self.SSUtilService.getURLParameter('forget_password_token');
        self.enquiryEmailCode = self.SSUtilService.getURLParameter('enquiry_email_confirmation');
        if (self.activationToken) {
            self.SSUserService.showActivationModal();
        } else if (self.show === 'enquirySuccessMessage') {
            self.SSUserService.showEnquirySuccessModal();
        } else if (self.show === 'feedback') {
            self.SSUserService.showFeedbackModal(self.SSUtilService.getURLParameter('type'));
        } else if (!self.SSUserService.getUser()) {
            if (self.show === 'login') {
                self.SSUserService.showLoginModal();
            } else if (self.show === 'register') {
                let asAgent = false;
                let registerParams = {};
                if (self.SSUtilService.getURLParameter('as_agent') == 1) {
                    asAgent = true;
                    registerParams["_as_agent"] = true;
                    registerParams["user_email"] = self.SSUtilService.getURLParameter('user_email');
                    registerParams["user_first_name"] = self.SSUtilService.getURLParameter('user_first_name');
                    registerParams["user_last_name"] = self.SSUtilService.getURLParameter('user_last_name');
                    registerParams["user_phone1"] = self.SSUtilService.getURLParameter('user_phone1');
                    registerParams["broker_agency_id"] = self.SSUtilService.getURLParameter('broker_agency_id');
                    registerParams["broker_reg_no"] = self.SSUtilService.getURLParameter('broker_reg_no');
                    registerParams["broker_nric"] = self.SSUtilService.getURLParameter('broker_nric');
                    registerParams["invite_code"] = self.SSUtilService.getURLParameter('invite_code');
                    registerParams["broker_is_support_staff"] = self.SSUtilService.getURLParameter('is_support_staff');
                    self.loadAgencies();
                }
                self.$timeout(function () {
                    self.SSUserService.showRegisterModal(asAgent, registerParams);
                });
            } else if (self.forgetPasswordToken) {
                self.SSUserService.showResetPasswordModal();
            }
        } else if (self.DMS_AGR  && self.SSUserService.getUser() && self.SSUserService.getUser().role == 'DEV_ADM' && self.SSUserService.getUser().agreement == 0) {
            self.SSUserService.showAgreementModal();
            console.log(self.SSUserService.getUser());

        } else if (self.SSUserService.getUser() && self.SSUserService.getUser().password_expiry_date && !self.SSUserService.getSkipPassword()) {
            let expiry_date = moment(self.SSUserService.getUser().password_expiry_date.date, 'YYYY-MM-DD HH:mm:ss');
            let today_date = moment();
            let days_left_expiry = expiry_date.diff(today_date, 'days');

            if (days_left_expiry <= 10) {
                self.SSUserService.days_left_expiry = days_left_expiry;
                self.SSUserService.showExpiryPasswordModal();
            }
        }

    }

    next(redirectTo) {
        const self = this;

        if (self.redirectTo) {
            redirectTo = self.redirectTo;
        } else if (self.$window.location.pathname !== '/') {
            redirectTo = self.$window.location.pathname;
        } else if (self.SSUserService.getUser()) {
            redirectTo = self.SSUserService.getHomeUrl();
        } else if (!redirectTo) {
            redirectTo = '/';
        }

        self.$window.location = redirectTo;
    }

    registerUser() {
        const self = this;

        self.showCaptchaError = false;

        if (self.SSUserService.validateRegisterParams()) return;

        var googleResponse = $('#captcha').val();
        if (!googleResponse) {
            self.showCaptchaError = true;
            return;
        }

        let params = self.SSUserService.registerParams;

        var url = 'register';
        if (params._as_agent) {
            url = 'register-broker';
        }

        self.registerInProcess = true;
        self.registerError = null;

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(
            function (responseObj) {
                loading(false);
                self.registerInProcess = false;

                if (responseObj instanceof Error) {

                    self.registerError = responseObj.serverMessage;
                    switch (responseObj.code) {
                        case 412:
                            self.registerError = 'Incorrect Information.';
                            break;
                        default:
                            self.registerError = 'Oops, Something went wrong. Please contact Showsuite support.';
                            break;
                    }

                    // Form Errors
                    self.SSUserService.enquireErrors = {};
                    self.SSUserService.registerErrors = {};

                    jQuery.each(responseObj.data, function (key, value) {
                        try {
                            self.SSUserService.enquireErrors[key] = value[0];
                        } catch (ex) {
                        }
                        try {
                            self.SSUserService.registerErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });
                    // Form Errors

                    return;
                }

                if (params._from_enquiry) {
                    self.SSUserService._sendEnquiry();
                } else {
                    self.SSUserService.showThanksForRegisterModal(params._as_agent);
                }
            }
        );
    }

    registerBuyer() {
        const self = this;

        if (self.SSUserService.validateRegisterParams()) return;

        let params = self.SSUserService.registerParams;

        var url = 'register-buyer';

        self.registerInProcess = true;
        self.registerError = null;

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(
            function (responseObj) {
                loading(false);
                self.registerInProcess = false;

                if (responseObj instanceof Error) {

                    self.registerError = responseObj.serverMessage;
                    switch (responseObj.code) {
                        case 412:
                            self.registerError = 'Incorrect Information.';
                            break;
                        default:
                            self.registerError = 'Oops, Something went wrong. Please contact Showsuite support.';
                            break;
                    }

                    // Form Errors
                    self.SSUserService.enquireErrors = {};
                    self.SSUserService.registerErrors = {};
                    jQuery.each(responseObj.data, function (key, value) {

                        try {
                            self.SSUserService.enquireErrors[key] = value[0];
                        } catch (ex) {
                        }
                        try {
                            self.SSUserService.registerErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });
                    // Form Errors

                    return;
                }
                self.buyer_id  = responseObj.data.buyer.id;
                console.log(">>>>>>>>>>");
                console.log(self.buyer_id);
                // Refresh developer's 'roles/permissions'
                if (params._from_enquiry) {
                    self.registerUser();
                    // self._sendEnquiry();
                } else {
                    self.SSUserService.showThanksForRegisterModal(params._as_agent);
                }

            }
        );
    }

    submitFeedback() {
        const self = this;

        if (!self.SSUtilService.validateForm(self, 'ctrl', jQuery('#feedbackModal')))
            return;

        let params = angular.copy(self.SSUserService.feedbackParams);
        if (params.data) {
            if (params.data && params.data.agency_id > 0) {
                params.data.agency_name = self.getAgencyName(params.data.agency_id);
            }

            params.data = JSON.stringify(params.data);
        }

        self.SSUserService.feedbackErrors = {};

        loading(true);
        self.SSHttpService.postAPIRequest('feedback', params).then(
            function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);

                    // Form Errors
                    self.SSUserService.feedbackErrors = {};
                    jQuery.each(response.data, function (key, value) {
                        try {
                            self.SSUserService.feedbackErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });
                    // Form Errors

                    return;
                }

                self.SSUserService.hideLoginModal();

                self.SSConfirmationService.getConfirmed(
                    'Thank You!',
                    'We will notify you about our next workshop soon.',
                    self.SSConfirmationService.TYPE_DEFAULT,
                    function () {
                        self.next();
                    }
                );
            }
        );
    }

    loginUser() {
        const self = this;

        if (self.validateLoginParams()) return;

        self.loginError = null;
        self.loginInProcess = true;

        if(self.allow_notifications) {
            self.SSUserService.requestNotificationPermission(self.FIREBASE_VAPID_KEY);
        }
        self.SSUserService.loginUser(self.email, self.password, self.remember_me, function (response) {
                self.loginInProcess = false;

                if (response instanceof Error) {
                    if (response.sub_code == self.TOKEN_SENT_ERROR || response.sub_code == self.TOKEN_MISSING_REQUIRED_PARAMS) {
                        self.loginError = "Unable to send SMS to your registered phone number. Please retry later or contact us at support@showsuite.com";
                    } else {
                        self.loginError = response.message;
                    }
                } else if (response.data && response.data.user) {
                    self.next(response.data.redirectTo);

                } else if (response.sub_code == self.TOKEN_SENT_SUCCESSFULLY || response.sub_code == self.TOKEN_WAS_ALREADY_SENT) {                   
                   self.SSUserService.show2FALoginModal((response.data && response.data.prefix_2fa)? response.data.prefix_2fa:'');
                }
            }
        );
    }

    login2FAUser() {
        const self = this;

        let headers = {
            'email': self.email,
            'password': self.password,
        };
        let Params = {
            'email': self.email,
            'remember_me' :self.remember_me,
            'twofa_code': self.SSUserService.prefix_2fa+self.twofa_code,
            'twofa_type': self.TYPE_2FA_LOGIN
        };

        self.loginError = null;

        loading(true);
        self.SSHttpService.postAPIRequest('2fa', Params, headers).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.loginError = "Invalid Code";
                return;
            }
            // Set current user object to local storage
            self.SSUserService.setUser(response.data.user);

            // Get complete profile and update in local storage
            self.SSUserService.getProfile(function(_response){
                self.SSUserService.setUser(_response.data.user);

                let redirectUrl = self.SSUserService.getHomeUrl();
                if(redirectUrl){
                    self.$window.location = redirectUrl;
                }
                else{
                    self.$window.location.reload();
                }
            });
        });
    }

    resetPassword() {
        const self = this;

        if (self.SSUserService.forget_password_token) {
            self.forgetPasswordToken = self.SSUserService.forget_password_token;
        }

        let params = {
            'forget_password_token': self.forgetPasswordToken,
            'activation_token': self.activationToken,
            'user_password': self.userPassword,
            'user_password_confirmation': self.userPasswordConfirmation
        };

        self.resetPasswordInProcess = true;
        self.resetPasswordError = null;
        self.resetPasswordSuccess = null;
        self.userPasswordErr = [];
        self.userPasswordConfirmationErr = [];
        if (self.SSUtilService.getURLParameter('is_developer') == 1) {
            self.isDeveloper = true;
        }
        if (self.SSUtilService.getURLParameter('is_amp') == 1) {
            self.isAmp = true;
        }
        // if (self.isDeveloper && !self.SSUserService.checkPasswordPolicy(self.userPassword)) {
        //     self.resetPasswordInProcess = false;
        //     self.resetPasswordError = 'Invalid Password';
        //     self.userPasswordErr = 'Your password must be at least 8 alphanumeric characters with at least one lowercase letter, one uppercase letter, a number, a symbol and not more than 14 alphanumeric characters.';
        //     return;
        // }
        if(self.userPassword == "" || !self.userPassword){
            self.userPasswordErr.push("Password field is required");
            self.resetPasswordInProcess = false;
            return;
        }
        else{
            if(self.userPasswordConfirmation != self.userPassword){
                self.userPasswordConfirmationErr.push("Password does not Match");
                self.resetPasswordInProcess = false;
                return;
            }
        }


        self.SSHttpService.putAPIRequest('reset-password', params).then(
            function (responseObj) {
                self.resetPasswordInProcess = false;
                if (responseObj instanceof Error) {
                    let errorData = responseObj.data;

                    self.resetPasswordError = responseObj.message;

                    if (_.has(errorData, 'user_password')) {
                        self.userPasswordErr = errorData.user_password;
                    }
                    if (_.has(errorData, 'user_password_confirmation')) {
                        self.userPasswordConfirmationErr = errorData.user_password_confirmation;
                    }

                    return;
                }

                let user = responseObj.data.user;
                user["status"] = SHOWSUITE.USER_STATUS_ACTIVE;

                self.SSUserService.setUser(responseObj.data.user);
                self.resetPasswordSuccess = responseObj.message;

                if(user && (self.isDeveloper || self.isAmp)){
                    self.email = user.email;
                    self.password = self.userPassword;
                    self.remember_me = false;
                    self.loginUser();
                }
            });
    }

    loadAgencies() {
        const self = this;

        if (self.agencies && self.agencies.length > 0) {
            return;
        }
        self.agenciesLoading = true;
        let params = {
            format: 1
        };

        self.SSHttpService.getAPIRequest('agencies', params).then(
            function (responseObj) {
                self.agenciesLoading = false;

                if (responseObj instanceof Error) {
                    self.agenciesLoadingError = "Unable to load agencies.";
                } else if (responseObj.data && responseObj.data.agencies) {
                    self.agencies = responseObj.data.agencies;
                }

            }
        );
    }

    getAgencyName(agencyId) {
        const self = this;

        let name = '';

        jQuery.each(self.agencies, function (i, e) {
            if (e.id == agencyId) {
                name = e.name;
                return false;
            }
        });

        return name;
    }

    sendEnquiry() {
        const self = this;

        self.showCaptchaError = false;

        if (self.SSUserService.validateEnquireParams()) return;

        var googleResponse = $('#captcha').val();
        if (!googleResponse) {
            self.showCaptchaError = true;
            return;
        }

        if (self.SSUserService.getUser()) {
            self.SSUserService._sendEnquiry();
        } else {
            self.registerBuyer();
        }
    }

    validateLoginParams() {
        const self = this;

        // Return if user is already logged-in
        if (self.SSUserService.getUser()) return;

        let hasError = false;
        self.loginErrors = {};

        if (!self.email) {
            self.loginErrors.email = "The Email field is required.";
            hasError = true;
        }
        if (!self.password) {
            self.loginErrors.password = "The Password field is required.";
            hasError = true;
        }
        if (!self.remember_me) {
            self.remember_me = false;
        }

        return hasError;
    }

}

CustomerLoginController.$inject = ['$scope', '$window', '$localStorage', '$parse', '$timeout', 'SSUserService', 'SSAppService', 'SSHttpService', 'SSUtilService', 'SSConfirmationService', 'SSAlertService', '$rootScope'];
app.controller('CustomerLoginController', CustomerLoginController);
