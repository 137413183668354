"use strict";

class CustomerLoadingController {
  constructor($scope, $localStorage, SSAppService, SSUtilService) {

    const self = this;

    self.$scope = $scope;
    self.$storage = $localStorage;
    self.SSAppService = SSAppService;
    self.SSUtilService = SSUtilService;
  }
}

CustomerLoadingController.$inject = ['$scope', '$localStorage', 'SSAppService', 'SSUtilService'];
app.controller('CustomerLoadingController', CustomerLoadingController);
