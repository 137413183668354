"use strict";

class KeyHandoverAppController {
    constructor($window, $scope) {
        const self = this;

        self.$window = $window;
        self.$scope = $scope;

        self.data = SHOWSUITE.data;

        self.init();
    }

    init() {
        const self = this;

    }

}

KeyHandoverAppController.$inject = ['$window', '$scope'];
app.controller('KeyHandoverAppController', KeyHandoverAppController);

