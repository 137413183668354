"use strict";

class CustomerTopbarController {

    constructor($window, $cookies, $localStorage, SSUserService, SSAppService, SSHttpService, SSStorageService,SSFirebaseMessagingService) {
        const self = this;

        self.SSUserService = SSUserService;
        self.SSAppService = SSAppService;
        self.SSHttpService = SSHttpService;
        self.SSStorageService = SSStorageService;
        self.SSFirebaseMessagingService = SSFirebaseMessagingService;
        self.$window = $window;
        self.$cookies = $cookies;
        self.$localStorage = $localStorage;

        self.init();
    }

    init() {
        const self = this;
        self.mainNavDropdowns();
        // self.changeUrlForLanguage();
    }

    // changeUrlForLanguage(){
    //     const self = this;
    //     let storage = self.SSStorageService.getFromLocalStorage('lang');
    //     let newUrl = self.$window.location.href;
    //     let check = true;
    //     if(storage != undefined){
    //         let parameters = self.$window.location.search;
    //         if(parameters)
    //             parameters.slice(1).split('&').forEach(function (pair) {
    //                 let values = pair.split('=');
    //                 if(values[0].trim() == 'lang'){
    //                     check = false;
    //                 }
    //             });
    //         if(check){
    //             if(parameters.length == 0){
    //                 newUrl += '?lang='+storage;
    //             }
    //             else{
    //                 newUrl += '&lang='+storage;
    //             }
    //             self.$window.location.replace(newUrl);
    //         }
    //     }
    // }

    redirectUrl(url){
        const self = this;

        self.$window.location.href = url;
    }

    detectIELte10() {
        const self = this;

        let version = null;

        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var edge = ua.indexOf('Edge/');

        if (msie > 0) {
            // IE 10 or older => return version number
            version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        } else if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        } else if (edge > 0) {
            // Edge (IE 12+) => return version number
            version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        } else {
            // other browser
        }

        return version && version < 10;
    }

    mainNavDropdowns() {
        
        $('.main-header nav ul li:not(:only-child)').click(function(e) {                              
            $(this).find('.nav-dropdown').toggle();
            // Close one dropdown when selecting another
            $('.nav-dropdown').not($(this).find('.nav-dropdown')).hide();
            e.stopPropagation();
        });
          // Clicking away from dropdown will remove the dropdown class
        $('html').click(function() {
            $('.nav-dropdown').hide();
        });
          // Toggle open and close nav styles on click
        $('#nav-toggle').click(function() {
            $('.main-header nav ul').slideToggle();
        });
          // Hamburger to X toggle
        $('#nav-toggle').on('click', function() {
            this.classList.toggle('active');
        });
    }

    languageChanged(language){
        const self = this;
        self.$cookies.put('lang',language);
        self.$window.location.reload();
        // let storage = self.SSStorageService.getFromLocalStorage('lang');
        // let parameters = self.$window.location.search.slice(1).split('&');
        // let newUrl = self.$window.location.href;
        // parameters.forEach(function (pair) {
        //     let values = pair.split('=');
        //     if(values[0].trim() == 'lang'){
        //         self.SSStorageService.saveToLocalStorage('lang',language);
        //         newUrl = newUrl.replace(pair,'lang='+language);
        //         self.$window.location.replace(newUrl);
        //     }
        // });
    }

    isSSSingapore() {
        return document.location.host == 'showsuite.com'
            || document.location.host == 'www.showsuite.com';
    }

    isSSThailand() {
        return document.location.host == 'thailand.showsuite.com';
    }

    isSSAustralia() {
        return document.location.host == 'australia.showsuite.com';
    }

}

CustomerTopbarController.$inject = ['$window', '$cookies', '$localStorage', 'SSUserService', 'SSAppService', 'SSHttpService', 'SSStorageService','SSFirebaseMessagingService'];
app.controller('CustomerTopbarController', CustomerTopbarController);
