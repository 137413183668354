"use strict";

class CustomerFooterController {
  constructor($scope, $localStorage, SSUserService, SSAppService, SSHttpService, SSUtilService) {

    const self = this;

    self.$scope = $scope;
    self.$storage = $localStorage;
    self.SSUserService = SSUserService;
    self.SSAppService = SSAppService;
    self.SSHttpService = SSHttpService;
    self.SSUtilService = SSUtilService;

    self.init();
  }

  init() {
    const self = this;
  }

  trackAboutUsAnalytics() {
    //Add to Firebase Analytics Event
    firebase.analytics().logEvent('FOOTER_ABOUTUS_CLICKS', 
        {
            'event_category': 'Footer of the page'
        });

    window.location.href = "/more/about-us";
  }

  trackPressAnalytics() {
    //Add to Firebase Analytics Event
    firebase.analytics().logEvent('FOOTER_PRESS_CLICKS', 
        {
            'event_category': 'Footer of the page'
        });

    window.location.href = "/more/cms/press";
  }

  trackContactUsAnalytics() {
    //Add to Firebase Analytics Event
    firebase.analytics().logEvent('FOOTER_CONTACT_US_CLICKS', 
        {
            'event_category': 'Footer of the page'
        });

    window.location.href = "/more/contact-us";
  }
}

CustomerFooterController.$inject = ['$scope', '$localStorage', 'SSUserService', 'SSAppService', 'SSHttpService', 'SSUtilService'];
app.controller('CustomerFooterController', CustomerFooterController);
