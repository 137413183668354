"use strict";

class CustomerSearchController {
  constructor($scope, $localStorage, SSAppService, SSHttpService, SSUtilService, SSSearchService) {

    const self = this;

    self.$scope = $scope;
    self.$storage = $localStorage;
    self.SSHttpService = SSHttpService;
    self.SSUtilService = SSUtilService;
    self.SSSearchService = SSSearchService;
    self.SSAppService = SSAppService;
    self.searchProperties = self.SSSearchService.searchProperties;

    self.selectedCountry = self.SSAppService.appServiceObject.selectedCountry;

    self.SSSearchService.userIsOnSearchResult = false;
    self.SSSearchService.searchTriggered = false;

    self.moreVisible = false;
    self.shown = false;

    self.init();
  }

  init() {
      const self = this;

      self.$scope.$watch(() => self.SSAppService.appServiceObject.selectedCountry, function (selectedCountry) {
          self.selectedCountry = selectedCountry;
      });

      self.SSAppService.needServerConfig();
  }

  search() {
    const self = this;

    self.shown = false;

    if (self.SSSearchService.userIsOnSearchResult) {
      self.SSSearchService.searchTriggered = true;
    } else {
      self.SSSearchService.resetSq();
      window.location.href = self.SSSearchService.getSearchUrlWithParams();
    }
  }

  reset() {
    const self = this;
    self.SSSearchService.reset();
  }

  toggleSearchBar() {
    const self = this;
    self.shown = !self.shown;
  }

  toggleMore() {
    const self = this;
    self.moreVisible = !self.moreVisible;
  }
}

CustomerSearchController.$inject = ['$scope', '$localStorage', 'SSAppService', 'SSHttpService', 'SSUtilService', 'SSSearchService'];
app.controller('CustomerSearchController', CustomerSearchController);
